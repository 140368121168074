<template>
  <component
    :is="container"
    class="labeled-text"
    :class="[
      horizontalLabel ? 'horizontal flex-row items-center' : 'flex-col',
      disabled || disable ? 'disabled' : null,
    ]"
    v-bind="$attrs"
    :disabled="disabled || disable || undefined"
    :role="role || undefined"
  >
    <component
      :is="labelContainer"
      v-if="$slots.helper || helper || label || horizontalLabel"
      :id="id"
      class="label"
      :title="label"
      :class="[
        labelClass,
        {
          'gap-1': !props.instructions,
        },
      ]"
      :style="[
        !noOverrideWidth && width && horizontalLabel && { width, flex: '0 0 auto' },
        !noOverrideWidth &&
          enableLabelMargin &&
          horizontalLabel && { margin: 'auto', marginRight: width ? undefined : '5px' },
      ]"
    >
      <slot :id="id" name="label">
        {{ label }}
      </slot>

      <div v-if="$slots.helper || helper">
        <input-helper :text="helper || ''">
          <slot :id="id" name="helper" />
        </input-helper>
      </div>
      <span v-if="validAttribute($attrs.required)" class="required">*</span>
    </component>
    <p v-if="props.instructions" :id="instructionsId" class="instructions">
      {{ props.instructions }}
    </p>
    <slot :id="id" name="text">
      <component
        :is="textContainer"
        v-if="text || $slots.default || badge"
        :class="['text flex-auto', boldText && 'bold', contentClass]"
      >
        <slot :id="id" :aria-described-by="instructionsId">
          <pre v-if="pre" class="labeled-text" v-text="text" />
          <template v-else>
            <span v-if="text" class="labeled-text">{{ text }}</span>
          </template>

          <template v-if="badge">
            <m-badge v-if="badge && badge.text" v-bind="badge" :class="['q-ml-sm', text]" />
            <m-badge v-else-if="badge" :text="badge" :class="['q-ml-sm', text]" />
          </template>
        </slot>
      </component>
    </slot>
  </component>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { useHorizontalLabel, useInputWidth } from "../../../composables/horizontalLabel";
import { validAttribute } from "../../../utils/misc";
import InputHelper from "../../InputHelper.vue";
import MBadge from "./../MBadge";
import { randomID } from "../../../utils/helpers";

const props = defineProps({
  id: String,
  label: {
    type: String,
    default: "",
  },
  labelClass: String,
  text: {
    type: null,
    default: "",
  },

  container: {
    type: String,
    default: "div",
  },
  labelContainer: {
    type: String,
    default: "span",
  },
  textContainer: {
    type: String,
    default: "div",
  },

  boldText: {
    type: Boolean,
    default: false,
  },

  horizontal: {
    type: Boolean,
    default: undefined,
  },
  labelWidth: String,

  forceHorizontalLabel: Boolean,

  noOverrideWidth: Boolean,
  multiline: Boolean,

  pre: {
    type: Boolean,
    default: (p: any) => {
      return !!p.text;
    },
  },

  helper: String,

  instructions: { type: String, required: false },

  contentClass: [String, Array, Object],

  enableLabelMargin: Boolean,
  badge: [Object, String],

  role: {
    type: String,
  },

  disable: Boolean,
  disabled: Boolean,
});

const horizontalLabel = useHorizontalLabel(props);
const width = useInputWidth(props);

const id = computed(() => props.id ?? randomID("labeled-text-"));
const instructionsId = computed(() => id.value + "-instructions");
</script>
<style lang="scss">
.labeled-text {
  display: flex;
  gap: 0.25rem;

  &.horizontal {
    flex: 1 1 auto;

    > .label {
      align-self: flex-start;
      margin-top: 10px;
      padding-right: 15px !important;
      text-align: right;
      justify-content: flex-end;
    }

    > legend {
      float: left;
    }
    > .text {
      margin-top: 10px;
    }
  }

  &.disbaled,
  &:has(.disabled) {
    .label {
      opacity: 0.6;
    }
  }

  .label {
    font-style: normal;
    font-size: 14px;
    line-height: 150%;

    color: var(--text-color);
    font-weight: bold;

    align-self: flex-start;
    word-wrap: normal;
    white-space: normal;

    align-items: center;
    display: flex;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;

    .required {
      color: var(--status-red);
    }
  }

  .instructions {
    color: var(--text-color-lightest);
    margin-bottom: var(--gap-1);
  }

  .text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .text,
  pre {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height */

    color: var(--text-color);

    margin: 0;

    word-break: break-word;
  }

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .bold > pre {
    font-weight: 600;
  }

  .text-area-detail {
    width: 100%;
  }

  ul {
    padding-inline-start: 14px;
  }
}

//fieldset.labeled-text:not(.horizontal) {
//  display: contents;
//}
</style>
