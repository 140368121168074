import Axios from "axios";
import printJS from "print-js";
import { date, debounce, throttle } from "quasar";
import { App, computed, defineComponent, inject, reactive, ref, watch } from "vue";
import { isNull } from "vue-composable";
import { RouteMeta, onBeforeRouteLeave } from "vue-router";
import { openModal, openPage, openSlideover } from "../composables/dialog/drawer";
import { patientSearch } from "../composables/dialog/patientSearch";
import { usePlayAudio } from "../composables/playAudio";
import { useSnackbar } from "../composables/snackbar";
import { router } from "../router";
import { pinia, useAuth, useMeeting, useNHS, usePanicButton } from "../store";
import { useMobileSplashScreen } from "../store/drawers/mobileSplash";
import { formatAddress, isValidPostcode } from "../utils/address";
import axios from "../utils/axios";
import { dispatch, listen } from "../utils/bus";
import {
  formatDate,
  formatDateTime,
  formatDateTimeMedicus,
  getAge,
  getMinutes,
  getMonths,
} from "../utils/date";
import { showDialog } from "../utils/dialog";
import { downloadFile, downloadFiles } from "../utils/download";
import { isValidNhsNumber, cis2Check, processCis2Response } from "../utils/nhs";
import { hasPermission } from "../utils/permission";
import { formatPhone } from "../utils/phone";
import { isMobile } from "../utils/resize";
import { ParsedRoute, parseRoute } from "../utils/route";
import { updateExpires } from "../utils/session";
import { showSnackbar } from "../utils/snackbar";
import { generateUUIDv7 } from "../utils/helpers";
import { useKeyboardShortcutsStore } from "../store/keyboard-shortcuts";

export function globalPlugin() {
  return {
    install(app: App) {
      // @ts-expect-error enable defineComponent to be used globally
      window.defineComponent = defineComponent;

      paramsMixin(app);

      // init snackbar context
      useSnackbar(app._context);

      // convert globalProperties to reactive object,
      // this will allow to unwrap usage
      // @ts-expect-error globalProperties is not reactive type
      const globalProperties = (app.config.globalProperties = reactive(
        app.config.globalProperties,
      ));

      globalProperties.$axios = axios;

      // {{ $formatDateTime(dateTimeValue) }} // 09 Feb 2020, 21:05
      // {{ $formatDateTime(dateTimeValue, true) }} // Sun 09 Feb 2020, 21:05
      globalProperties.$formatDateTime = formatDateTime;

      // {{ $formatDate(dateTimeValue) }} // 09 Feb 2020
      // {{ $formatDate(dateTimeValue, true) }} // Sun 09 Feb 2020
      globalProperties.$formatDate = formatDate;

      globalProperties.$formatDateTimeMedicus = formatDateTimeMedicus;

      globalProperties.$getAge = getAge;
      globalProperties.$getMonths = getMonths;
      globalProperties.$getMinutes = getMinutes;
      // format phone number
      globalProperties.$formatPhone = formatPhone;
      globalProperties.$formatAddress = formatAddress;
      globalProperties.$isValidPostcode = isValidPostcode;
      globalProperties.$date = date;

      globalProperties.$notify = showSnackbar;
      globalProperties.$listen = listen;
      globalProperties.$dispatch = dispatch;

      globalProperties.$dialog = showDialog;
      globalProperties.$hasPermission = hasPermission;

      globalProperties.$debounce = (window as any).debounce = debounce;
      globalProperties.$throttle = (window as any).throttle = throttle;

      globalProperties.$downloadFile = downloadFile;
      globalProperties.$downloadFiles = downloadFiles;

      globalProperties.$isMobile = isMobile;

      globalProperties.$mobileSubnav = false;

      globalProperties.$isValidNhsNumber = isValidNhsNumber;
      globalProperties.$cis2Check = cis2Check;
      globalProperties.$processCis2Response = processCis2Response;

      globalProperties.$patientSearch = patientSearch;

      globalProperties.$openSlideover = openSlideover;
      globalProperties.$openModal = openModal;
      globalProperties.$openPage = openPage;

      globalProperties.$onBeforeRouteLeave = onBeforeRouteLeave;
      globalProperties.$clearQuery = clearQuery;

      globalProperties.$generateUUIDv7 = generateUUIDv7;

      globalProperties.$showUnsupportedMobile = (message?: string) =>
        useMobileSplashScreen().show(message);

      globalProperties.$joinMeeting = (id: string) => {
        return useMeeting().connect(id);
      };

      globalProperties.$playAudio = function (url: string | Blob, duration?: number) {
        const player = usePlayAudio(url, duration);
        player.play();
        return player;
      };
      globalProperties.$printPdf = (url: string) => {
        return new Promise<void>((res, rej) => {
          printJS({
            printable: url,
            type: "pdf",

            onError: rej,
            onPrintDialogClose: res,
          });
        });
      };
      globalProperties.$print = printJS;

      patchAxios(app);
      patchRouterLogin(app);

      // fake store for medicus
      const keyboardShortcuts = useKeyboardShortcutsStore(pinia);
      globalProperties.$activateShortcut = keyboardShortcuts.activateShortcut;
      globalProperties.$deactivateShortcut = keyboardShortcuts.deactivateShortcut;

      const auth = useAuth(pinia);
      const nhs = useNHS(pinia);
      const panicButton = usePanicButton(pinia);

      globalProperties.$pairWithDesktopApp = panicButton.pairWithDesktopApp;

      // temp object to allow access to a sma
      globalProperties.$store = {
        dispatch(event: any, data: any) {
          switch (event) {
            case "auth/nhsLogin": {
              return nhs.login(data);
            }
            case "auth/logout": {
              return auth.logout(data);
            }
            case "auth/gbNhsCis2PairedUsers": {
              return nhs.gbNhsCis2PairedUsers();
            }
            case "auth/gbNhsCis2PairedUserValidate": {
              return nhs.gbNhsCis2PairedUserValidate(data);
            }
            case "auth/gbNhsCis2PairedUserRemove": {
              return nhs.gbNhsCis2PairedUserRemove(data);
            }
          }
        },
        state: computed(() => ({
          auth: {
            user: auth.user,
            config: auth.config,
            tenants: auth.tenants,
            selectedTenant: auth.selectedTenant,
          },
          desktopApp: {
            pairedPort: panicButton.pairedPort,
          },
        })),
      };
    },
  };
}
const desiredQuery: Record<string, string | string[]> = reactive({
  ...router.currentRoute.value.query,
});
let shouldReplace = false;
let dontUpdate = false;

export function clearQuery() {
  Object.keys(desiredQuery).forEach((key) => {
    delete desiredQuery[key];
  });
}

function updateQuery() {
  if (dontUpdate) {
    dontUpdate = false;
    return;
  }
  const query = { ...desiredQuery };

  Object.keys(router.currentRoute.value.query).forEach((key) => {
    if (!(key in query)) {
      if (key.endsWith("[]")) query[key.slice(0, -2)] = undefined;
      else query[key] = undefined;
    }
  });

  const navigate = shouldReplace ? router.replace : router.push;

  return navigate({
    query,
  });
}

watch(desiredQuery, debounce(updateQuery, 100), {
  deep: true,
});

router.afterEach((to) => {
  dontUpdate = true;
  Object.assign(desiredQuery, to.query);
});
// enables `options.params` mixin
function paramsMixin(app: App) {
  app.mixin({
    data() {
      const p: Record<string, string> | undefined = this.$options.params;
      if (!p || typeof p !== "object") return {};

      const query = this.$route.query;
      const ret: Record<any, any> = {};
      for (const queryKey in p) {
        const componentKey = p[queryKey];

        let v = query[queryKey];

        try {
          // @ts-expect-error parse type
          v = JSON.parse(query[queryKey]);
        } catch {
          //ignore
        }
        ret[componentKey] = v;
      }
      return ret;
    },
    /**
     * @this Vue
     */
    beforeMount() {
      const p: Record<string, string> = this.$options.params;
      if (!p || typeof p !== "object") return;

      const isDynamic = inject("isDynamic", false);
      const isLoaded = inject(
        "isLoaded",
        computed(() => true),
      );

      const componentPath = this.$route.params.componentPath;
      const query = this.$route.query;

      for (const queryKey in p) {
        const componentKey = p[queryKey];

        // NOTE php arrays are named suffixed with `[]`, vue router already correctly parses them
        const r = ref(query[queryKey] ?? query[`${queryKey}[]`] ?? this.$data[componentKey]);

        if (query[queryKey]) {
          try {
            r.value = JSON.parse(query[queryKey]);
          } catch {
            //ignore
          }
        } else if (query[`${queryKey}[]`] && !Array.isArray(r.value)) {
          r.value = [r.value];
        }

        this.$data[componentKey] = r;

        watch(
          () => this.$route.query[queryKey],
          (v) => {
            r.value = v;
          },
        );

        watch(
          () => this.$route.query[queryKey + "[]"],
          (v) => {
            if (v === undefined) {
              r.value = undefined;
            } else if (Array.isArray(v)) {
              // it was updated by r.value change, do not update r.value again
              if (JSON.stringify(v) === JSON.stringify(r.value)) return;
              r.value = [...v];
            } else {
              r.value = [v];
            }
          },
        );

        watch(
          r,
          (r, o) => {
            //if the path is different, aka changed page, do not update the query
            if (componentPath !== this.$route.params.componentPath) return;
            // same array, do not update
            if (JSON.stringify(r) === JSON.stringify(o)) return;

            shouldReplace = isDynamic && isLoaded.value;

            const key = Array.isArray(r) ? queryKey + "[]" : queryKey;
            const value = Array.isArray(r)
              ? r
              : typeof r === "string"
              ? r
              : isNull(r) || r === undefined
              ? undefined
              : JSON.stringify(r);

            if (value === undefined || (Array.isArray(r) && r.length === 0)) {
              if (desiredQuery[key]) {
                console.log("clearing ", key, value, r);
                delete desiredQuery[key];
              }
            } else {
              desiredQuery[key] = value;
            }
          },
          { deep: true },
        );
      }
    },
  });
}

const auth = useAuth(pinia);

function patchAxios(app: App) {
  // permissions
  axios.interceptors.response.use(
    function (response) {
      updateExpires();

      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error: any) {
      if (Axios.isCancel(error)) {
        return Promise.reject(error);
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const STATUS = error.response && error.response.status ? error.response.status : "Unknown";

      if (STATUS == 401) {
        // Do not call logout if we are were attempting to login
        if (
          error.request.responseURL !== auth.loginUrl &&
          error.request.responseURL.includes("send-password-reset") === false
        ) {
          if (router.currentRoute.value.redirectedFrom?.name === "homepage") {
            auth.logout();
          } else {
            auth.logout(true);
          }

          return Promise.reject();
        }
      } else if (STATUS == 403) {
        updateExpires();
        // do not redirect if the route is 403
        // this happens when multiple requests are made at the same
        // time and some return 403
        if (
          error?.config?.showError !== false &&
          typeof error.response.data?.errorCode !== "string" &&
          (!router.currentRoute.value.name ||
            (router.currentRoute.value.name as string)?.indexOf("403") === -1)
        ) {
          app.config.globalProperties
            .$dialog({
              title: "Incorrect Permissions",
              text: "Sorry, you don't have the permission to perform this action.",
              noButtons: true,
              type: "dialog",
            })
            .onFinally(() => {
              dispatch("dialog:incorrect-permissions:close");
            });

          return Promise.reject();
        }
      } else {
        updateExpires();

        if (error?.config?.showError !== false) {
          let url: string | null = null;

          if (error.config?.url) {
            const urlWithoutQueryParams = error.config.url.split("?")[0];
            if (urlWithoutQueryParams) url = urlWithoutQueryParams;
          }

          showSnackbar({
            title: "Error",
            message: `<p>We’re sorry, but we’re experiencing a problem on our end.</p><p>Please contact the Medicus Support Team for further information. Our Live Operations Team have also been notified.</p><p>Error code: ${STATUS}</p>${
              url ? `<p style="word-break: break-all;">URL: ${url}</p>` : null
            }`,
            type: "danger",
            timeout: 9999999,
          });
        }
      }

      return Promise.reject(error);
    },
  );
}

function patchRouterLogin(app: App) {
  router.beforeEach((to) => {
    const globalProperties = app.config.globalProperties;
    const parsedRoute: ParsedRoute & { meta?: RouteMeta; componentPath?: string } = parseRoute(
      to.fullPath,
    );

    const loggedIn = auth.isLoggedIn;

    if (to.meta) {
      parsedRoute.meta = to.meta;
    }
    if (to.meta && to.meta.componentPath) {
      parsedRoute.componentPath = to.meta.componentPath as string;
    }

    globalProperties.$medicusRoute = parsedRoute;

    const forceLogout = to.matched.some((record) => record.meta.forceLogout);
    const isPublic = to.matched.some((record) => record.meta.public);
    // const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut);

    if (forceLogout && loggedIn) {
      return auth.logout(to.fullPath);
    }

    if (!isPublic && !loggedIn) {
      const redirect = to.meta.redirect
        ? to.query.redirect?.toString() || to.fullPath.replace(location.origin, "")
        : to.fullPath.replace(location.origin, "");
      return auth.logout(redirect);
    }

    // // Do not allow user to visit login page or register page if they are logged in
    // if (loggedIn && onlyWhenLoggedOut) {
    //   const tenant = extractTenant(to.fullPath);
    //   if (tenant) {
    //     return next({
    //       name: `${tenant}-Home`,
    //     });
    //   } else {
    //     return next({
    //       name: "tenants",
    //       query: {
    //         redirect: to.query.redirect,
    //       },
    //     });
    //   }
    // }
  });
}
