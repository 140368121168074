import { ComponentPublicInstance, inject, ref, warn, watch } from "vue";
import { date } from "quasar";

export function traverseParentProvided(
  vm: ComponentPublicInstance<any>,
  func: (o: Record<any, any>) => boolean,
  limit = 100,
) {
  let parent: ComponentPublicInstance | undefined | null = vm.$parent;
  let i = 0;
  do {
    //@ts-expect-error provided is not declared
    const providedRaw: (() => void) | object | undefined = parent.$?.provides;
    const provided = providedRaw
      ? Array.isArray(providedRaw)
        ? providedRaw.reduce((p, c) => {
            p[c] = parent[c];
            return p;
          }, {})
        : providedRaw
      : undefined;

    if (provided && func(provided)) {
      return provided;
    } else {
      parent = parent?.$parent;
    }
    ++i;
  } while (parent && parent !== vm.$root && i < limit);
  return undefined;
}

export function randomID(prefix?: string) {
  const r = Math.random().toString(36).substr(2, 9);

  return prefix ? prefix + "_" + r : r;
}

export function generateUUIDv7() {
  const UNIX_TS_MS_BITS = 48;
  const VER_DIGIT = "7";
  const SEQ_BITS = 12;
  const VAR = 0b10;
  const VAR_BITS = 2;
  const RAND_BITS = 62;
  let prevTimestamp = -1;
  let seq = 0;

  // Negative system clock adjustments are ignored to keep monotonicity
  const timestamp = Math.max(Date.now(), prevTimestamp);
  seq = timestamp === prevTimestamp ? seq + 1 : 0;
  prevTimestamp = timestamp;

  const var_rand = new Uint32Array(2);
  crypto.getRandomValues(var_rand);
  var_rand[0] = (VAR << (32 - VAR_BITS)) | (var_rand[0]! >>> VAR_BITS);

  const digits =
    timestamp.toString(16).padStart(UNIX_TS_MS_BITS / 4, "0") +
    VER_DIGIT +
    seq.toString(16).padStart(SEQ_BITS / 4, "0") +
    var_rand[0]!.toString(16).padStart((VAR_BITS + RAND_BITS) / 2 / 4, "0") +
    var_rand[1]!.toString(16).padStart((VAR_BITS + RAND_BITS) / 2 / 4, "0");

  return (
    digits.slice(0, 8) +
    "-" +
    digits.slice(8, 12) +
    "-" +
    digits.slice(12, 16) +
    "-" +
    digits.slice(16, 20) +
    "-" +
    digits.slice(20)
  );
}

export function getMinDate(v: string | number | Date, hasTime: boolean = false) {
  const d = namedDate(v as string);
  if (hasTime) {
    return d;
  }
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}
export function getMaxDate(v: string | number | Date, hasTime: boolean = false) {
  const d = namedDate(v as string);
  if (hasTime) {
    return d;
  }
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59, 9);
}

export function namedDate(d: string) {
  if (typeof d !== "string") {
    return new Date(d);
  }
  const now = new Date();
  switch (d.toLowerCase()) {
    case "now":
      return now;
    case "today":
      return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    case "tomorrow":
      return new Date(now.setDate(now.getDate() + 1));
    case "yesterday":
      return new Date(now.setDate(now.getDate() - 1));
    case "month":
      return date.addToDate(now, { months: 1 });
    default:
      return new Date(d);
  }
}

const namedPath: Record<string, { get(o: any): any; set(o: any, v: any): void }> = {};

export function pathAccessor(path: string) {
  let acc = namedPath[path];
  if (!acc) {
    try {
      const [condition] = path.split(" ");
      const get = new Function("item", `return item.${condition}`);

      const content = `${
        ~condition.indexOf("?") ? `if(item.${condition}) ` : ""
      }item.${condition.replace(/\?/g, "")} = value`;

      const set = new Function("item", "value", content);

      namedPath[path] = acc = {
        get,
        set,
      } as any;
    } catch (e) {
      console.error(e);
    }
  }
  return acc;
}

export function hasParent(e: HTMLElement, check: (e: HTMLElement) => boolean) {
  let parent = e.parentElement;
  while (parent) {
    if (check(parent)) {
      return true;
    }
    parent = parent.parentElement;
  }
  return false;
}

export function isInQMenu(e: HTMLElement) {
  return hasParent(e, (e) => e.classList.contains("q-menu"));
}

export function checkLabel<LabelProp extends string>(
  props: { [K in LabelProp]?: string | undefined } & {
    name?: string;
    placeholder?: string | undefined;
    srLabel?: string | undefined;
  },
  componentName: string,
  propName: LabelProp,
  shouldCheck = ref<boolean | null | undefined>(false),
) {
  const renderTree = inject<string[]>("renderTree", []);
  watch(
    [
      () => {
        return props[propName] || false;
      },
      shouldCheck,
    ],
    ([label, shouldCheck]) => {
      if (label || props.srLabel || !shouldCheck) return;

      console.log("Label missing", {
        props: { ...props },
        propName,
        componentName,
        renderTree,
      });

      const text = `Label missing on "${componentName}"
Component: ${renderTree[renderTree.length - 1]}
Name: "${props.name}"
Property: "${propName}"
Placeholder: "${props.placeholder}"
This will cause accessibility issues and must be fixed.`;

      warn(text);
    },
    {
      flush: "sync",
      immediate: true,
    },
  );
}
